import { MouseEvent, useCallback, useState } from 'react';

interface ScenarioTypes {
  biodiversity: boolean;
  waterAreaSurface: boolean;
  waterScarcity: boolean;
  caseStudies: boolean;
}

interface MapTypes {
  isLegendActive: boolean;
  isScenarioTypesInfoActive: boolean;
}

interface ToggleRequestDataFormModalTypes {
  isRequestDataFormVisible: boolean;
}

interface MobileFiltersModalTypes {
  areMobileFiltersActive: boolean;
}

interface ErrorBoundariesTypes {
  isMenuOpened: boolean;
}

type CombinedProps = ScenarioTypes &
  MapTypes &
  ToggleRequestDataFormModalTypes &
  MobileFiltersModalTypes &
  ErrorBoundariesTypes;

const useToggle = () => {
  const [toggleState, setToggleState] = useState<CombinedProps>({
    areMobileFiltersActive: false,
    biodiversity: false,
    isLegendActive: false,
    isMenuOpened: false,
    isRequestDataFormVisible: false,
    isScenarioTypesInfoActive: false,
    waterAreaSurface: false,
    waterScarcity: false,
    caseStudies: false,
  });

  const handleToggleState = useCallback(
    (toggleType: string, e?: MouseEvent) => {
      if (e) e.stopPropagation();
      setToggleState((prevState) => {
        const key = toggleType as string as keyof typeof toggleState;
        return { ...toggleState, [toggleType]: !prevState[key] };
      });
    },
    [toggleState, setToggleState]
  );

  return { handleToggleState, toggleState };
};

export default useToggle;
