import { t } from 'i18next';
import React from 'react';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

export const handleTooltipInfo = (
  activeScenario: string,
  activeScenarioTypeId: string,
  tooltipInfo: any
) => {
  return activeScenario === 'biodiversity' ? (
    <div tw="flex justify-between text-sm items-center">
      <p> {t('select_country_biodiversity')}</p>
      <strong>
        {tooltipInfo?.target?.feature.stats[
          activeScenarioTypeId.toLocaleLowerCase()
        ]?.toLocaleString()}
        %
      </strong>
    </div>
  ) : activeScenario === 'water_scarcity' ? (
    <>
      <div tw="flex justify-between text-sm items-center">
        <p>
          {t('select_country_water_scarcity_water_avalability_index_value_1')}
        </p>
        <strong>
          {tooltipInfo?.target?.feature.stats.waterStress?.toLocaleString()}
        </strong>
      </div>
      <div tw="flex justify-between text-sm items-center">
        <p>
          {t('select_country_water_scarcity_water_avalability_index_value_2')}
        </p>
        <strong>
          {tooltipInfo?.target?.feature.stats.waterScarcity?.toLocaleString()}
        </strong>
      </div>
      <div tw="flex justify-between text-sm items-center">
        <p>
          {t('select_country_water_scarcity_water_avalability_index_value_3')}
        </p>
        <strong>
          {tooltipInfo?.target?.feature.stats.absoluteScarcity?.toLocaleString()}
        </strong>
      </div>
    </>
  ) : (
    tooltipInfo?.target?.feature?.stats && (
      <div tw="flex justify-between text-sm items-center">
        <p>{t('select_country_water_area')}</p>
        <strong>
          {tooltipInfo?.target?.feature?.stats[
            activeScenarioTypeId.toLowerCase()
          ]?.toLocaleString()}
          %
        </strong>
      </div>
    )
  );
};
