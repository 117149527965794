/* eslint-disable max-len */
import { useWaterScarcity } from 'context/waterScarcity';
import { t } from 'i18next';
import { TooltipInfoProps } from 'interfaces/tooltipInfo.interface';
import React from 'react';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { handleTooltipInfo } from 'utils/tooltip';

const Tooltip = ({ tooltipInfo }: TooltipInfoProps) => {
  const { activeScenario, activeScenarioTypeId } = useWaterScarcity();

  return (
    tooltipInfo?.target?.feature.stats && (
      <div
        css={[
          tw`flex transition duration-300 top-0 rounded-[0.5rem] bg-gray-500 p-[1rem] max-w-[17rem] w-full text-white flex-col gap-[0.5rem] fixed z-[1001]`,
        ]}
        style={{
          left: `${tooltipInfo.originalEvent.clientX + 20}px`,
          top: `${tooltipInfo.originalEvent.clientY}px`,
        }}
      >
        <div tw="flex gap-[1rem] mb-1 items-center font-medium text-xl">
          {tooltipInfo?.target?.feature.file_url && (
            <img
              width={32}
              src={tooltipInfo?.target?.feature.file_url}
              alt="Flag"
            />
          )}
          <h3>{tooltipInfo?.target?.feature?.properties.name}</h3>
        </div>
        {handleTooltipInfo(
          activeScenario.id,
          activeScenarioTypeId,
          tooltipInfo,
        )}
        <button tw="flex font-medium mt-2 gap-[0.5rem] text-left">
          {t('explore')}
        </button>
      </div>
    )
  );
};

export default Tooltip;
