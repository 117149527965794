import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { urls } from 'config/urls';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { GlobalStyles } from 'twin.macro';
import App from './App';
import reportWebVitals from './reportWebVitals';

const customFetch = (_: string, options: object) =>
  fetch(
    `${urls.API_HOST_URL}/graphql?cache_version=${urls.cacheVersion}`,
    options
  );

const apolloHttpLink = createHttpLink({
  fetch: customFetch,
});

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: apolloHttpLink,
  uri: `${urls.API_HOST_URL}/graphql`,
});

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <ApolloProvider client={apolloClient}>
      <App />
      <GlobalStyles />
    </ApolloProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
