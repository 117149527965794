import { MarksPair } from 'interfaces/slider.interface';

export const waterScarcityMarks: MarksPair[] = [
  {
    label: '2000',
    value: 2000,
  },
  {
    label: '2010',
    value: 2010,
  },
  {
    label: '2020',
    value: 2020,
  },
  {
    label: '2030',
    value: 2030,
  },
  {
    label: '2040',
    value: 2040,
  },
  {
    label: '2050',
    value: 2050,
  },
];

export const nonWaterScarcityMarks: MarksPair[] = [
  {
    label: '2000',
    value: 2000,
  },
  {
    label: '2010',
    value: 2010,
  },
  {
    label: '2020',
    value: 2020,
  },
  {
    label: '2030',
    value: 2030,
  },
];
