/* eslint-disable max-len */
import FormContainer from 'components/Form/FormContainer';
import ScenariosChange from 'components/MapChangeView/ScenariosChange';
import CountryInput from 'components/Search/CountryInput';
import MobileYearDropdown from 'components/Slider/MobileYearDropdown';
import useToggle from 'hooks/useToggle';
import { t } from 'i18next';
import { MobileFilters } from 'interfaces/mobile-filters.interface';
import React from 'react';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

const AsideMobileFilters = ({
  areMobileFiltersActive,
  handleToggleState,
}: MobileFilters) => {
  const { toggleState } = useToggle();

  return (
    <>
      <aside
        css={[
          tw`sm:flex translate-x-[100%] transition duration-500 flex-col hidden fixed left-0 top-[3.8rem] w-full overflow-scroll bg-blue-100 bottom-0 z-[1] gap-[1rem] p-[2rem 1rem]`,
          areMobileFiltersActive && tw`translate-x-0`,
        ]}
      >
        <CountryInput
          isFromMobileFilters={true}
          handleToggleState={handleToggleState}
        />
        <ScenariosChange isFromMobileFilters={true} />
        <MobileYearDropdown isFromMobileFilters={true} />
        <a
          href="/Methodology/methodology.html"
          tw="text-center mt-[1rem] text-sm uppercase bg-blue-50 text-blue-800 rounded-[0.5rem] p-[1rem] hover:bg-blue-800 focus:bg-blue-800 hover:text-white focus:text-white"
        >
          {t('methodology')}
        </a>
        <button
          onClick={() => handleToggleState('isRequestDataFormVisible')}
          tw="text-sm uppercase bg-blue-50 text-blue-800 rounded-[0.5rem] p-[1rem] hover:bg-blue-800 focus:bg-blue-800 hover:text-white focus:text-white"
        >
          {t('request_data')}
        </button>
      </aside>{' '}
      {toggleState.isRequestDataFormVisible && (
        <div tw="fixed w-full h-full overflow-scroll top-0 left-0 right-0 bottom-0 bg-white z-[2]">
          <div
            css={[
              tw`max-w-[41rem] translate-y-[-50%] translate-x-[-50%] w-full sm:rounded-none sm:h-full left-[50%] top-[50%] z-[1] gap-[1rem] text-sm bg-white rounded-[0.5rem] text-center p-[2rem 2.5rem 1.5rem 2.5rem]`,
              toggleState.isRequestDataFormVisible &&
                tw`translate-x-[-50%] translate-y-[-50%] z-50 absolute`,
            ]}
          >
            <FormContainer handleToggleState={handleToggleState} />
          </div>
        </div>
      )}
    </>
  );
};

export default AsideMobileFilters;
