import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Long: bigint;
};

export type BioDiversityCountry = {
  __typename?: 'BioDiversityCountry';
  country: Country;
  layers: BioDiversityLayers;
  stats: BioDiversityStats;
};

export type BioDiversityLayers = {
  __typename?: 'BioDiversityLayers';
  directQuality?: Maybe<Scalars['String']>;
  extraction?: Maybe<Scalars['String']>;
  indirectQuality?: Maybe<Scalars['String']>;
  overall?: Maybe<Scalars['String']>;
};

export type BioDiversityStats = {
  __typename?: 'BioDiversityStats';
  directQuality?: Maybe<Scalars['Float']>;
  extraction?: Maybe<Scalars['Float']>;
  indirectQuality?: Maybe<Scalars['Float']>;
  overall?: Maybe<Scalars['Float']>;
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export enum Direction {
  Falling = 'Falling',
  Rising = 'Rising',
  Steady = 'Steady'
}

export enum MonthNameShort {
  Apr = 'Apr',
  Aug = 'Aug',
  Dec = 'Dec',
  Feb = 'Feb',
  Jan = 'Jan',
  Jul = 'Jul',
  Jun = 'Jun',
  Mar = 'Mar',
  May = 'May',
  Nov = 'Nov',
  Oct = 'Oct',
  Sep = 'Sep'
}

export type OverallBioDiversity = {
  __typename?: 'OverallBioDiversity';
  overallPercentage: Scalars['Int'];
  values: Array<BioDiversityCountry>;
};

export type OverallSurfaceArea = {
  __typename?: 'OverallSurfaceArea';
  overall: ValueWithPercentageOverTime;
  values: Array<SurfaceAreaCountry>;
};

export type OverallWaterScarcity = {
  __typename?: 'OverallWaterScarcity';
  overall: ValueWithPercentageOverTime;
  values: Array<WaterScarcityCountry>;
};

export type Query = {
  __typename?: 'Query';
  bioDiversity?: Maybe<OverallBioDiversity>;
  surfaceArea?: Maybe<OverallSurfaceArea>;
  waterScarcity?: Maybe<OverallWaterScarcity>;
};


export type QueryBioDiversityArgs = {
  countryIds: Array<Scalars['String']>;
  decade: Scalars['Int'];
};


export type QuerySurfaceAreaArgs = {
  countryIds: Array<Scalars['String']>;
  fromYear: Scalars['Int'];
  toYear: Scalars['Int'];
};


export type QueryWaterScarcityArgs = {
  countryIds: Array<Scalars['String']>;
  decade: Scalars['Int'];
};

export type SurfaceAreaCountry = {
  __typename?: 'SurfaceAreaCountry';
  country: Country;
  layers: SurfaceAreaLayers;
  stats: SurfaceAreaStats;
};

export type SurfaceAreaLayers = {
  __typename?: 'SurfaceAreaLayers';
  overall?: Maybe<Scalars['String']>;
  permanent?: Maybe<Scalars['String']>;
  wetlands?: Maybe<Scalars['String']>;
};

export type SurfaceAreaStats = {
  __typename?: 'SurfaceAreaStats';
  overall?: Maybe<Scalars['Int']>;
  permanent?: Maybe<Scalars['Int']>;
  wetlands?: Maybe<Scalars['Int']>;
};

export type ValueWithPercentageOverTime = {
  __typename?: 'ValueWithPercentageOverTime';
  direction: Direction;
  percentage: Scalars['Int'];
  value: Scalars['Long'];
};

export type WaterScarcityCountry = {
  __typename?: 'WaterScarcityCountry';
  country: Country;
  layers: WaterScarcityLayers;
  stats: WaterScarcityStats;
};

export type WaterScarcityLayers = {
  __typename?: 'WaterScarcityLayers';
  availability?: Maybe<Scalars['String']>;
  population?: Maybe<Scalars['String']>;
};

export type WaterScarcityStats = {
  __typename?: 'WaterScarcityStats';
  absoluteScarcity?: Maybe<Scalars['Int']>;
  driestMonth?: Maybe<MonthNameShort>;
  percentagePplInWaterScarceAreas?: Maybe<Scalars['Int']>;
  population?: Maybe<Scalars['Int']>;
  waterScarcity?: Maybe<Scalars['Int']>;
  waterStress?: Maybe<Scalars['Int']>;
};

export type CompleteNumberFragment = { __typename?: 'ValueWithPercentageOverTime', value: bigint, direction: Direction, percentage: number };

export type HeroSectionQueryVariables = Exact<{
  fromYear: Scalars['Int'];
  toYear?: InputMaybe<Scalars['Int']>;
}>;


export type HeroSectionQuery = { __typename?: 'Query', biodiversity?: { __typename?: 'OverallBioDiversity', overallPercentage: number } | null, water_scarcity?: { __typename?: 'OverallWaterScarcity', overall: { __typename?: 'ValueWithPercentageOverTime', value: bigint, direction: Direction, percentage: number } } | null, water_area_surface?: { __typename?: 'OverallSurfaceArea', overall: { __typename?: 'ValueWithPercentageOverTime', value: bigint, direction: Direction, percentage: number } } | null };

export type WaterAreaSurfaceQueryVariables = Exact<{
  fromYear: Scalars['Int'];
  toYear?: Scalars['Int'];
}>;


export type WaterAreaSurfaceQuery = { __typename?: 'Query', data?: { __typename?: 'OverallSurfaceArea', overall: { __typename?: 'ValueWithPercentageOverTime', percentage: number, value: bigint }, values: Array<{ __typename?: 'SurfaceAreaCountry', country: { __typename?: 'Country', id: string, name?: string | null }, stats: { __typename?: 'SurfaceAreaStats', permanent?: number | null, wetlands?: number | null, overall?: number | null }, layers: { __typename?: 'SurfaceAreaLayers', permanent?: string | null, wetlands?: string | null, overall?: string | null } }> } | null };

export type BiodiversityQueryVariables = Exact<{
  decade: Scalars['Int'];
}>;


export type BiodiversityQuery = { __typename?: 'Query', data?: { __typename?: 'OverallBioDiversity', overallPercentage: number, values: Array<{ __typename?: 'BioDiversityCountry', country: { __typename?: 'Country', id: string, name?: string | null }, stats: { __typename?: 'BioDiversityStats', extraction?: number | null, overall?: number | null, direct_quality?: number | null, indirect_quality?: number | null }, layers: { __typename?: 'BioDiversityLayers', directQuality?: string | null, indirectQuality?: string | null, extraction?: string | null, overall?: string | null } }> } | null };

export type WaterScarcityQueryVariables = Exact<{
  decade: Scalars['Int'];
}>;


export type WaterScarcityQuery = { __typename?: 'Query', data?: { __typename?: 'OverallWaterScarcity', overall: { __typename?: 'ValueWithPercentageOverTime', value: bigint, direction: Direction, percentage: number }, values: Array<{ __typename?: 'WaterScarcityCountry', country: { __typename?: 'Country', id: string, name?: string | null }, stats: { __typename?: 'WaterScarcityStats', population?: number | null, waterScarcity?: number | null, waterStress?: number | null, absoluteScarcity?: number | null, driestMonth?: MonthNameShort | null, availability?: number | null }, layers: { __typename?: 'WaterScarcityLayers', population?: string | null, availability?: string | null } }> } | null };

export const CompleteNumberFragmentDoc = gql`
    fragment CompleteNumber on ValueWithPercentageOverTime {
  value
  direction
  percentage
}
    `;
export const HeroSectionDocument = gql`
    query heroSection($fromYear: Int!, $toYear: Int = 2030) {
  biodiversity: bioDiversity(decade: $fromYear, countryIds: []) {
    overallPercentage
  }
  water_scarcity: waterScarcity(decade: $fromYear, countryIds: []) {
    overall {
      ...CompleteNumber
    }
  }
  water_area_surface: surfaceArea(
    fromYear: $fromYear
    toYear: $toYear
    countryIds: []
  ) {
    overall {
      ...CompleteNumber
    }
  }
}
    ${CompleteNumberFragmentDoc}`;

/**
 * __useHeroSectionQuery__
 *
 * To run a query within a React component, call `useHeroSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeroSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeroSectionQuery({
 *   variables: {
 *      fromYear: // value for 'fromYear'
 *      toYear: // value for 'toYear'
 *   },
 * });
 */
export function useHeroSectionQuery(baseOptions: Apollo.QueryHookOptions<HeroSectionQuery, HeroSectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HeroSectionQuery, HeroSectionQueryVariables>(HeroSectionDocument, options);
      }
export function useHeroSectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeroSectionQuery, HeroSectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HeroSectionQuery, HeroSectionQueryVariables>(HeroSectionDocument, options);
        }
export type HeroSectionQueryHookResult = ReturnType<typeof useHeroSectionQuery>;
export type HeroSectionLazyQueryHookResult = ReturnType<typeof useHeroSectionLazyQuery>;
export type HeroSectionQueryResult = Apollo.QueryResult<HeroSectionQuery, HeroSectionQueryVariables>;
export function refetchHeroSectionQuery(variables: HeroSectionQueryVariables) {
      return { query: HeroSectionDocument, variables: variables }
    }
export const WaterAreaSurfaceDocument = gql`
    query waterAreaSurface($fromYear: Int!, $toYear: Int! = 2030) {
  data: surfaceArea(fromYear: $fromYear, toYear: $toYear, countryIds: []) {
    overall {
      percentage
      value
    }
    values {
      country {
        id
        name
      }
      stats {
        permanent
        wetlands
        overall
      }
      layers {
        permanent
        wetlands
        overall
      }
    }
  }
}
    `;

/**
 * __useWaterAreaSurfaceQuery__
 *
 * To run a query within a React component, call `useWaterAreaSurfaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useWaterAreaSurfaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaterAreaSurfaceQuery({
 *   variables: {
 *      fromYear: // value for 'fromYear'
 *      toYear: // value for 'toYear'
 *   },
 * });
 */
export function useWaterAreaSurfaceQuery(baseOptions: Apollo.QueryHookOptions<WaterAreaSurfaceQuery, WaterAreaSurfaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WaterAreaSurfaceQuery, WaterAreaSurfaceQueryVariables>(WaterAreaSurfaceDocument, options);
      }
export function useWaterAreaSurfaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WaterAreaSurfaceQuery, WaterAreaSurfaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WaterAreaSurfaceQuery, WaterAreaSurfaceQueryVariables>(WaterAreaSurfaceDocument, options);
        }
export type WaterAreaSurfaceQueryHookResult = ReturnType<typeof useWaterAreaSurfaceQuery>;
export type WaterAreaSurfaceLazyQueryHookResult = ReturnType<typeof useWaterAreaSurfaceLazyQuery>;
export type WaterAreaSurfaceQueryResult = Apollo.QueryResult<WaterAreaSurfaceQuery, WaterAreaSurfaceQueryVariables>;
export function refetchWaterAreaSurfaceQuery(variables: WaterAreaSurfaceQueryVariables) {
      return { query: WaterAreaSurfaceDocument, variables: variables }
    }
export const BiodiversityDocument = gql`
    query biodiversity($decade: Int!) {
  data: bioDiversity(decade: $decade, countryIds: []) {
    overallPercentage
    values {
      country {
        id
        name
      }
      stats {
        direct_quality: directQuality
        indirect_quality: indirectQuality
        extraction
        overall
      }
      layers {
        directQuality
        indirectQuality
        extraction
        overall
      }
    }
  }
}
    `;

/**
 * __useBiodiversityQuery__
 *
 * To run a query within a React component, call `useBiodiversityQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiodiversityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiodiversityQuery({
 *   variables: {
 *      decade: // value for 'decade'
 *   },
 * });
 */
export function useBiodiversityQuery(baseOptions: Apollo.QueryHookOptions<BiodiversityQuery, BiodiversityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BiodiversityQuery, BiodiversityQueryVariables>(BiodiversityDocument, options);
      }
export function useBiodiversityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BiodiversityQuery, BiodiversityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BiodiversityQuery, BiodiversityQueryVariables>(BiodiversityDocument, options);
        }
export type BiodiversityQueryHookResult = ReturnType<typeof useBiodiversityQuery>;
export type BiodiversityLazyQueryHookResult = ReturnType<typeof useBiodiversityLazyQuery>;
export type BiodiversityQueryResult = Apollo.QueryResult<BiodiversityQuery, BiodiversityQueryVariables>;
export function refetchBiodiversityQuery(variables: BiodiversityQueryVariables) {
      return { query: BiodiversityDocument, variables: variables }
    }
export const WaterScarcityDocument = gql`
    query waterScarcity($decade: Int!) {
  data: waterScarcity(decade: $decade, countryIds: []) {
    overall {
      ...CompleteNumber
    }
    values {
      country {
        id
        name
      }
      stats {
        population
        waterScarcity
        waterStress
        absoluteScarcity
        availability: percentagePplInWaterScarceAreas
        driestMonth
      }
      layers {
        population
        availability
      }
    }
  }
}
    ${CompleteNumberFragmentDoc}`;

/**
 * __useWaterScarcityQuery__
 *
 * To run a query within a React component, call `useWaterScarcityQuery` and pass it any options that fit your needs.
 * When your component renders, `useWaterScarcityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaterScarcityQuery({
 *   variables: {
 *      decade: // value for 'decade'
 *   },
 * });
 */
export function useWaterScarcityQuery(baseOptions: Apollo.QueryHookOptions<WaterScarcityQuery, WaterScarcityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WaterScarcityQuery, WaterScarcityQueryVariables>(WaterScarcityDocument, options);
      }
export function useWaterScarcityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WaterScarcityQuery, WaterScarcityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WaterScarcityQuery, WaterScarcityQueryVariables>(WaterScarcityDocument, options);
        }
export type WaterScarcityQueryHookResult = ReturnType<typeof useWaterScarcityQuery>;
export type WaterScarcityLazyQueryHookResult = ReturnType<typeof useWaterScarcityLazyQuery>;
export type WaterScarcityQueryResult = Apollo.QueryResult<WaterScarcityQuery, WaterScarcityQueryVariables>;
export function refetchWaterScarcityQuery(variables: WaterScarcityQueryVariables) {
      return { query: WaterScarcityDocument, variables: variables }
    }