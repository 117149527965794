import './App.css';
import ErrorBoundary from 'components/ErrorBoundary';
import { WaterScarcityContextProvider } from 'context/waterScarcity';
import en from 'i18n/en.json';
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import React from 'react';
import TagManager from 'react-gtm-module';
import { initReactI18next } from 'react-i18next';
import WaterScarcity from 'views/WaterScarcity';

const tagManagerArgs = {
  dataLayerName: 'pageView',
  gtmId: 'G-W76BYNPB0Y',
};

TagManager.initialize(tagManagerArgs);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(detector) // detect user language
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: true,
    },
    resources: {
      en: {
        translation: en,
      },
    },
  });

const App = () => {
  TagManager.dataLayer(tagManagerArgs);

  return (
    <ErrorBoundary>
      <WaterScarcityContextProvider>
        <WaterScarcity />
      </WaterScarcityContextProvider>
    </ErrorBoundary>
  );
};

export default App;
