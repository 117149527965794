/* eslint-disable max-len */
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import arrow from 'assets/images/arrow.svg';
import DynamicSvg from 'components/DynamicSVG/DynamicSVG';
import { useWaterScarcity } from 'context/waterScarcity';
import { t } from 'i18next';
import { ActiveScenarioTypes } from 'interfaces/active-scenario-types.interface';
import React from 'react';
/** @jsxImportSource @emotion/react */
import { theme } from 'twin.macro';
import { findActiveScenarioType } from 'utils/utilities';

export const CustomSelect = styled(Select)<any>(() => ({
  '.MuiInputBase-input': {
    color: theme`colors.blue.700`,
  },
  '.MuiSelect-icon': {
    display: 'none',
  },
}));

const MobileSubHeader = () => {
  const { activeScenarioTypeId, activeScenario, setActiveScenarioIdOrTypeId } =
    useWaterScarcity();

  const handleOptions = () =>
    activeScenario?.types
      .filter(({ id }: ActiveScenarioTypes) => id !== activeScenarioTypeId)
      .map(({ id, title }: ActiveScenarioTypes) => (
        <MenuItem key={id} value={id} tw="flex gap-[1rem] text-blue-700">
          <DynamicSvg iconName={id} fill={theme`colors.blue.700`} />
          {t(title)}
        </MenuItem>
      ));

  const handleChange = (event: SelectChangeEvent) =>
    setActiveScenarioIdOrTypeId({
      id: event.target.value,
      name: 'activeScenarioTypeId',
    });

  return (
    <div tw="sm:flex flex-col text-sm hidden mt-[1rem] gap-[0.5rem]">
      <h4 tw="uppercase tracking-[0.2rem] text-blue-700">
        {t(activeScenario?.map_type_header)}
      </h4>
      <CustomSelect
        style={{
          background: `url(${arrow}) no-repeat 100%`,
          backgroundPosition: 'right 1rem center',
        }}
        tw="border border-blue-500 rounded-[0.5rem] h-[2.75rem] w-full mt-[0.5rem]"
        onChange={handleChange}
        value={activeScenarioTypeId}
        renderValue={() => (
          <div tw="flex items-center text-blue-700 gap-[1rem]">
            <DynamicSvg
              iconName={
                findActiveScenarioType(
                  activeScenario?.types,
                  activeScenarioTypeId
                )?.id
              }
              fill={theme`colors.blue.700`}
            />
            <span>
              {t(
                findActiveScenarioType(
                  activeScenario?.types,
                  activeScenarioTypeId
                )?.title
              )}
            </span>
          </div>
        )}
      >
        {handleOptions()}
      </CustomSelect>
    </div>
  );
};

export default MobileSubHeader;
