/* eslint-disable max-len */
import DynamicSvg from 'components/DynamicSVG/DynamicSVG';
import { useWaterScarcity } from 'context/waterScarcity';
import { t } from 'i18next';
import { ActiveScenarioTypes } from 'interfaces/active-scenario-types.interface';
import React from 'react';
/** @jsxImportSource @emotion/react */
import tw, { theme } from 'twin.macro';

const Types = () => {
  const { activeScenario, activeScenarioTypeId, setActiveScenarioIdOrTypeId } =
    useWaterScarcity();

  const getActiveScenarioTypes = () =>
    activeScenario?.types.map(({ id, title }: ActiveScenarioTypes) => (
      <button
        data-testid="types-of-view"
        key={id}
        onClick={() =>
          setActiveScenarioIdOrTypeId({
            id,
            name: 'activeScenarioTypeId',
          })
        }
        css={[
          tw`rounded-[0.5rem] hover:bg-blue-100 focus:bg-blue-100 flex flex-col items-center justify-end gap-[0.5rem] bg-blue-50 p-[1rem] h-[6rem] w-[6rem] hover:text-blue-800 focus:text-blue-800`,
          activeScenarioTypeId === id
            ? tw`bg-blue-800 text-white hover:bg-blue-800 focus:bg-blue-800 hover:text-white focus:text-white`
            : tw`bg-blue-50 `,
        ]}
      >
        <DynamicSvg
          iconName={id}
          fill={`${
            activeScenarioTypeId === id
              ? theme`colors.white`
              : theme`colors.blue.800`
          }`}
        />
        {t(title)}
      </button>
    ));

  return (
    <div tw="flex justify-between items-center text-sm gap-[0.5rem] sm:hidden relative">
      {getActiveScenarioTypes()}
    </div>
  );
};

export default Types;
