import { css } from '@emotion/react';
import styled from '@emotion/styled';
import tw, { theme } from 'twin.macro';

export const UseCasesLabel = styled.label([
  tw`w-[2.25rem] h-[1.25rem] rounded-[2.125rem] flex bg-blue-50 cursor-pointer items-center p-[0.1rem]`,
  css`
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      background: ${theme`colors.white`};
      transition: 0.2s;
    }
  `,
]);

export const UseCases = styled.input([
  tw`w-[2rem] h-[0.75rem]`,
  css`
    &:checked + label {
      background: ${theme`colors.blue.800`};
      transition: 0.2s;

      &::after {
        content: '';
        display: block;
        margin-left: 1.06rem;
      }
    }
  `,
]);
