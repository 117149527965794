/* eslint-disable max-len */
import globe from 'assets/images/heroSection/globe.svg';
import { t } from 'i18next';
import React from 'react';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const ExploreMap = () => (
  <a
    href="#map_water_surface"
    tw="bg-blue-400 hover:bg-blue-600 rounded-l-lg border-0 absolute top-[8.25rem] sm:top-[6.25rem] right-0 p-[0.75rem 1.3rem] flex justify-center items-center z-[1]"
  >
    <img src={globe} alt="Explore the map" />
    <span tw="text-white font-medium uppercase leading-[1.15rem] ml-[1.5rem] sm:hidden w-[5.9rem]">
      {t('explore_map')}
    </span>
  </a>
);

export default ExploreMap;
