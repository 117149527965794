export const biodiversity2010Value = 17;
export const biodiversity2020Value = 18;
export const biodiversity2030Value = 19;

export const area2010Value = 29737254;
export const area2020Value = 33419974;
export const area2030Value = 45239095;

export const water2010Value = 1942306305;
export const water2020Value = 2220365127;
export const water2030Value = 2558561585;
export const water2040Value = 2831998272;
export const water2050Value = 3038767958;

export const backgroundsPath = '/assets/images/heroSection/backgrounds/';
