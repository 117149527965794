/* eslint-disable max-len */
import DynamicSvg from 'components/DynamicSVG/DynamicSVG';
import { useWaterScarcity } from 'context/waterScarcity';
import { t } from 'i18next';
import React, { Fragment } from 'react';
/** @jsxImportSource @emotion/react */
import { theme } from 'twin.macro';

const TypesDescription = () => {
  const { activeScenario } = useWaterScarcity();

  const getActiveScenarioTypes = () =>
    activeScenario?.types.map(({ id, description_title, description }: any) => (
      <Fragment key={id}>
        <div tw="grid grid-cols-[4rem, 1fr] items-center">
          <DynamicSvg iconName={id} fill={theme`colors.white`} />
          <div tw="flex flex-col text-sm">
            <h4 tw="pb-[0.375rem] font-bold">{t(description_title)}</h4>
            <p>{t(description)}</p>
          </div>
        </div>
        <hr tw="last:hidden bg-blue-50 h-[1px]" />
      </Fragment>
    ));

  return <>{getActiveScenarioTypes()}</>;
};

export default TypesDescription;
