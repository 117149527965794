import { FormDataTypes, InputTypes } from 'interfaces/form.interface';
import { FormEvent, useState } from 'react';

const useForm = (
  initialState: FormDataTypes = {
    companyEmail: '',
    companyName: '',
    firstName: '',
    joinMailingList: false,
    lastName: '',
    message: '',
  },
  onValidated: any
) => {
  const [formData, setFormData] = useState(initialState);

  const handleInputChange = ({ name, value, type, checked }: InputTypes) =>
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });

  const handleReset = () =>
    setFormData({
      companyEmail: '',
      companyName: '',
      firstName: '',
      joinMailingList: false,
      lastName: '',
      message: '',
    });

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onValidated({
      companyEmail: formData.companyEmail,
      companyName: formData.companyName,
      firstName: formData.firstName,
      joinMailingList: formData.joinMailingList,
      lastName: formData.lastName,
      message: formData.message,
    });
  };

  return { formData, handleInputChange, handleReset, handleSubmit };
};

export default useForm;
