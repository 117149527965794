import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { useWaterScarcity } from 'context/waterScarcity';
import { SliderProps } from 'interfaces/slider.interface';
import React, { useLayoutEffect } from 'react';
/** @jsxImportSource @emotion/react */
import tw, { theme } from 'twin.macro';
import { nonWaterScarcityMarks, waterScarcityMarks } from 'utils/slider';
import { isWaterScarcityScenarioActive } from 'utils/utilities';

const StyledYearsSlider = styled(Slider, {
  shouldForwardProp: (prop: any) => prop !== 'isFromMap',
})<SliderProps>(({ isFromMap }: any) => ({
  '.MuiSlider-mark': {
    borderRadius: 50,
    color: isFromMap ? theme`colors.blue.300` : theme`colors.blue.50`,
    height: '1rem',
    width: '1rem',
  },
  '.MuiSlider-markActive': {
    background: isFromMap ? theme`colors.blue.500` : theme`colors.blue.200`,
    opacity: 1,
  },
  '.MuiSlider-markLabel': {
    color: isFromMap ? theme`colors.blue.800` : theme`colors.white`,
    fontSize: '1rem',
    fontWeight: 400,
    transform: 'translateX(-30%)',
  },
  '.MuiSlider-markLabelActive': {
    fontWeight: 600,
  },
  '.MuiSlider-rail': {
    color: isFromMap ? theme`colors.blue.500` : theme`colors.blue.50`,
    height: '1px',
  },
  '.MuiSlider-thumb': {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    color: isFromMap ? theme`colors.blue.500` : theme`colors.blue.200`,
    height: '2rem',
    marginLeft: '0.4rem',
    width: '2rem',
  },
  '.MuiSlider-track': {
    // background: isFromMap ? theme`colors.blue.500` : theme`colors.blue.200`,
    // border: 0
    // height: "5px"
  },
}));

const CustomSliderContainer = ({ isFromMap }: SliderProps) => {
  const { activeScenarioId, selectedYears, setSelectedYears } =
    useWaterScarcity();

  const value: number | (number | undefined)[] =
    activeScenarioId === 'water_area_surface'
      ? [selectedYears.fromYear, selectedYears.toYear]
      : selectedYears.fromYear;

  const handleChange = (_: Event, value: number | number[]) =>
    setSelectedYears({ id: value, name: 'selectedYears' });

  const activeTrack =
    activeScenarioId === 'water_area_surface' ? 'normal' : false;
  const activeLabels = activeScenarioId === 'water_area_surface' ? 'on' : 'off';

  useLayoutEffect(() => {
    const rangeLabels = document.querySelectorAll<HTMLElement>(
      '.MuiSlider-valueLabelLabel'
    );

    if (rangeLabels.length > 1) {
      rangeLabels[0].innerText = 'From';
      rangeLabels[1].innerText = 'To';
    }
  }, [activeScenarioId, selectedYears]);

  return (
    <div
      css={[
        tw`absolute md:relative bottom-[3rem] md:bottom-[1rem] w-[calc(100% - 1rem)] p-[0 4rem] sm:hidden`,
        isFromMap &&
          tw`w-[calc(100% - 9rem)] p-0 !bottom-[-2rem] 2xl:!bottom-0 m-auto md:left-0 left-[4rem]`,
      ]}
    >
      <StyledYearsSlider
        getAriaLabel={() => 'Years range/slider'}
        getAriaValueText={(value: number) => value.toString()}
        onChange={handleChange}
        value={value as number | number[]}
        step={10}
        track={activeTrack}
        valueLabelDisplay={activeLabels}
        min={2000}
        max={isWaterScarcityScenarioActive(activeScenarioId) ? 2050 : 2030}
        marks={
          isWaterScarcityScenarioActive(activeScenarioId)
            ? waterScarcityMarks
            : nonWaterScarcityMarks
        }
        disableSwap
        tw="m-0 p-0"
        isFromMap={isFromMap}
      />
    </div>
  );
};

export default CustomSliderContainer;
