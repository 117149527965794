/* eslint-disable max-len */
import DynamicSvg from 'components/DynamicSVG/DynamicSVG';
import { useWaterScarcity } from 'context/waterScarcity';
import { t } from 'i18next';
import { MobileFilters } from 'interfaces/mobile-filters.interface';
import React from 'react';
/** @jsxImportSource @emotion/react */
import tw, { theme } from 'twin.macro';
import { getActiveScenario, initialYear, scenarios } from 'utils/utilities';

const ScenariosChange = ({ isFromMobileFilters }: MobileFilters) => {
  const {
    activeScenarioId,
    setActiveScenarioIdOrTypeId,
    selectedYears,
    setSelectedYears,
  } = useWaterScarcity();

  return (
    <div
      css={[
        tw`sm:p-[1rem] px-[4rem] text-blue-800 md:flex-col sm:mt-[1rem] gap-[1.5rem] sm:gap-[1rem] flex justify-between items-center py-[2rem] sm:py-0`,
        isFromMobileFilters && tw`sm:p-0`,
      ]}
    >
      <h3 tw="uppercase tracking-[0.2rem] text-blue-700 text-center lg:text-sm max-w-[56.375rem] w-full">
        {t('change_view')}
      </h3>
      {Object.entries(scenarios).map((scenario) => {
        return (
          <button
            key={scenario[0]}
            onClick={() => {
              setActiveScenarioIdOrTypeId({
                id: scenario[1].id,
                name: 'activeScenarioId',
              });
              setActiveScenarioIdOrTypeId({
                id: getActiveScenario(scenario[1].id, scenarios)?.types[0].id,
                name: 'activeScenarioTypeId',
              });
              if (
                scenario[0] === 'water_scarcity' &&
                selectedYears.fromYear >= 2040
              ) {
                setSelectedYears({
                  id: selectedYears.fromYear,
                  name: 'selectedYears',
                });
              } else {
                setSelectedYears({
                  id: initialYear,
                  name: 'selectedYears',
                });
              }
            }}
            css={[
              tw`max-w-[22rem] md:max-w-none flex items-center bg-blue-50 hover:bg-blue-100 p-[1rem] lg:p-[0.5rem] md:p-[1rem] rounded-[0.5rem] h-[3.188rem] w-full sm:ml-0 lg:text-sm md:text-regular`,
              activeScenarioId === scenario[1].id
                ? tw`bg-blue-800 text-white hover:bg-blue-800 focus:bg-blue-800`
                : tw`bg-blue-50 `,
            ]}
          >
            <DynamicSvg
              iconName={scenario[1].id}
              fill={`${
                activeScenarioId === scenario[1].id
                  ? theme`colors.white`
                  : theme`colors.blue.800`
              }`}
            />
            <span tw="w-[calc(100% - 2.5rem)]">{t(scenario[1].title)}</span>
          </button>
        );
      })}
    </div>
  );
};

export default ScenariosChange;
