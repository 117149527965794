/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable max-len */
import arrow from 'assets/images/arrow.svg';
import help from 'assets/images/help.svg';
import MobileSubHeader from 'components/MapSubHeader/MobileSubHeader';
import MobileYearDropdown from 'components/Slider/MobileYearDropdown';
import { useWaterScarcity } from 'context/waterScarcity';
import useLeaflet from 'hooks/useLeaflet';
import useToggle from 'hooks/useToggle';
import { t } from 'i18next';
import { MapLegend } from 'interfaces/map-legend.interface';
import React from 'react';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import {
  isWaterAreaSurfaceScenarioActive,
  isWaterScarcityScenarioActive,
  mapLegend,
} from 'utils/utilities';

const Legend = () => {
  const { activeScenarioId, activeScenarioTypeId, selectedCountry } =
    useWaterScarcity();
  const { handleToggleState, toggleState } = useToggle();
  const { handleMouseEnterLegend, handleMouseLeaveLegend } = useLeaflet();

  return (
    <>
      <div tw="lg:flex flex-col gap-[1rem] hidden">
        <div
          css={[
            isWaterAreaSurfaceScenarioActive(activeScenarioId) &&
              tw`flex gap-[0.5rem] items-center relative z-[1001]`,
          ]}
        >
          <h3
            css={[
              tw`text-blue-700 uppercase tracking-[0.2rem] text-sm`,
              activeScenarioTypeId === 'POPULATION' || selectedCountry
                ? tw`pb-[1rem]`
                : null,
            ]}
          >
            {selectedCountry &&
            activeScenarioId === 'water_scarcity' &&
            activeScenarioTypeId === 'AVAILABILITY'
              ? 'Average water availability per person in pixels'
              : selectedCountry && activeScenarioId === 'water_area_surface'
                ? 'Change in hectares per pixels'
                : isWaterScarcityScenarioActive(activeScenarioId) &&
                    activeScenarioTypeId === 'POPULATION'
                  ? 'Number of people in each grid'
                  : isWaterAreaSurfaceScenarioActive(activeScenarioId)
                    ? 'change in % per pixel'
                    : isWaterScarcityScenarioActive(activeScenarioId)
                      ? 'Share of Population living in water scarce areas'
                      : 'share of threatened freshwater species'}
          </h3>
          {isWaterAreaSurfaceScenarioActive(activeScenarioId) && (
            <img
              tw="cursor-pointer"
              id="info"
              width={20}
              alt="Info"
              src={help}
            />
          )}
          {isWaterAreaSurfaceScenarioActive(activeScenarioId) && (
            <div tw="hidden absolute p-[0.5rem] text-white text-xs bg-gray-500 left-0 top-[-6rem] z-[401] rounded-[0.5rem] w-full">
              {t('info_pixel')}
            </div>
          )}
          {isWaterAreaSurfaceScenarioActive(activeScenarioId) && (
            <div tw="hidden absolute p-[0.5rem] text-white text-xs bg-gray-500 left-0 top-[-3rem] z-[401] rounded-[0.5rem] w-full">
              {t('price_info')}
            </div>
          )}
        </div>
        <button
          tw="flex flex-col items-start text-blue-700 uppercase tracking-[0.2rem] text-xs"
          onClick={() => handleToggleState('isLegendActive')}
        >
          <span tw="flex pb-[0.5rem]">
            {t('click_interact')}
            <img
              css={[
                tw`pl-[1.125rem]`,
                toggleState.isLegendActive && tw`rotate-180 pr-[1.125rem]`,
              ]}
              src={arrow}
              alt="Arrow"
            />
          </span>
        </button>
        {toggleState.isLegendActive && (
          <div tw="grid grid-cols-[repeat(2,1fr)] justify-between gap-[1rem]">
            {selectedCountry &&
            activeScenarioId === 'water_scarcity' &&
            activeScenarioTypeId === 'AVAILABILITY'
              ? mapLegend.water_scarcity_availability.map(
                  ({ color, value }: MapLegend) => (
                    <button
                      key={value}
                      tw="flex items-center gap-[0 0.4rem] text-sm"
                    >
                      <span
                        style={{ backgroundColor: color }}
                        tw="rounded-[50%] w-[1.25rem] h-[1.25rem] block"
                      ></span>
                      <span>{value}</span>
                    </button>
                  ),
                )
              : selectedCountry && activeScenarioId === 'water_area_surface'
                ? mapLegend.water_area_surface_inner.map(
                    ({ color, value }: MapLegend) => (
                      <button
                        key={value}
                        tw="flex items-center gap-[0 0.4rem] text-sm"
                      >
                        <span
                          style={{ backgroundColor: color }}
                          tw="rounded-[50%] w-[1.25rem] h-[1.25rem] block"
                        ></span>
                        <span>{value}</span>
                      </button>
                    ),
                  )
                : isWaterScarcityScenarioActive(activeScenarioId) &&
                    activeScenarioTypeId === 'POPULATION'
                  ? mapLegend.population.map(({ color, value }: MapLegend) => (
                      <button
                        key={value}
                        tw="flex items-center gap-[0 0.4rem] text-sm"
                      >
                        <span
                          style={{ backgroundColor: color }}
                          tw="rounded-[50%] w-[1.25rem] h-[1.25rem] block"
                        ></span>
                        <span>{value}</span>
                      </button>
                    ))
                  : mapLegend[activeScenarioId].map(
                      ({ color, value, id }: MapLegend) => (
                        <button
                          key={value}
                          name={id}
                          tw="flex items-center gap-[0 0.4rem] text-sm"
                          onMouseEnter={handleMouseEnterLegend}
                          onMouseLeave={handleMouseLeaveLegend}
                        >
                          <span
                            style={{ backgroundColor: color }}
                            tw="rounded-[50%] w-[1.25rem] h-[1.25rem] block"
                          ></span>
                          <span>{value}</span>
                        </button>
                      ),
                    )}
          </div>
        )}
      </div>
      <div tw="rounded-[0.5rem] border border-blue-500 p-[1rem] lg:hidden relative">
        <div
          css={[
            isWaterAreaSurfaceScenarioActive(activeScenarioId) &&
              tw`flex gap-[0.5rem] items-center`,
          ]}
        >
          <h3
            css={[
              tw`text-blue-700 uppercase tracking-[0.2rem] text-sm`,
              activeScenarioTypeId === 'POPULATION' || selectedCountry
                ? tw`pb-[1rem]`
                : null,
            ]}
          >
            {selectedCountry &&
            activeScenarioId === 'water_scarcity' &&
            activeScenarioTypeId === 'AVAILABILITY'
              ? 'Average water availability per person in pixels'
              : selectedCountry && activeScenarioId === 'water_area_surface'
                ? 'Change in hectares per pixels'
                : isWaterScarcityScenarioActive(activeScenarioId) &&
                    activeScenarioTypeId === 'POPULATION'
                  ? 'Number of people in each grid'
                  : isWaterAreaSurfaceScenarioActive(activeScenarioId)
                    ? 'change in % per pixel'
                    : isWaterScarcityScenarioActive(activeScenarioId)
                      ? 'Share of Population living in water scarce areas'
                      : 'share of threatened freshwater species'}
          </h3>
          {isWaterAreaSurfaceScenarioActive(activeScenarioId) &&
            !selectedCountry && (
              <img
                tw="cursor-pointer"
                id="info"
                width={20}
                alt="Info"
                src={help}
              />
            )}
          {isWaterAreaSurfaceScenarioActive(activeScenarioId) && (
            <div tw="hidden absolute p-[0.5rem] text-white text-xs bg-gray-500 left-0 top-[-6rem] z-[401] rounded-[0.5rem] w-full">
              {t('info_pixel')}
            </div>
          )}
          {isWaterAreaSurfaceScenarioActive(activeScenarioId) && (
            <div tw="hidden absolute p-[0.5rem] text-white text-xs bg-gray-500 left-0 top-[-3rem] z-[401] rounded-[0.5rem] w-full">
              {t('price_info')}
            </div>
          )}
        </div>
        {activeScenarioTypeId === 'POPULATION' ||
          (!selectedCountry && (
            <p tw="text-blue-700 uppercase tracking-[0.2rem] text-sm pt-[0.5rem] pb-[1.125rem]">
              {t('hover_to_interact')}
            </p>
          ))}
        <div tw="flex flex-col gap-[1rem]">
          {selectedCountry &&
          activeScenarioId === 'water_scarcity' &&
          activeScenarioTypeId === 'AVAILABILITY'
            ? mapLegend.water_scarcity_availability.map(
                ({ color, value }: MapLegend) => (
                  <button
                    key={value}
                    tw="flex items-center gap-[0 0.4rem] text-sm"
                  >
                    <span
                      style={{ backgroundColor: color }}
                      tw="rounded-[50%] w-[1.25rem] h-[1.25rem] block"
                    ></span>
                    <span>{value}</span>
                  </button>
                ),
              )
            : selectedCountry && activeScenarioId === 'water_area_surface'
              ? mapLegend.water_area_surface_inner.map(
                  ({ color, value }: MapLegend) => (
                    <button
                      key={value}
                      tw="flex items-center gap-[0 0.4rem] text-sm"
                    >
                      <span
                        style={{ backgroundColor: color }}
                        tw="rounded-[50%] w-[1.25rem] h-[1.25rem] block"
                      ></span>
                      <span>{value}</span>
                    </button>
                  ),
                )
              : isWaterScarcityScenarioActive(activeScenarioId) &&
                  activeScenarioTypeId === 'POPULATION'
                ? mapLegend.population.map(({ color, value }: MapLegend) => (
                    <button
                      key={value}
                      tw="flex items-center gap-[0 0.4rem] text-sm"
                    >
                      <span
                        style={{ backgroundColor: color }}
                        tw="rounded-[50%] w-[1.25rem] h-[1.25rem] block"
                      ></span>
                      <span>{value}</span>
                    </button>
                  ))
                : mapLegend[activeScenarioId].map(
                    ({ color, value, id }: MapLegend) => (
                      <button
                        key={value}
                        name={id}
                        tw="flex items-center gap-[0 0.4rem] text-sm"
                        onMouseEnter={handleMouseEnterLegend}
                        onMouseLeave={handleMouseLeaveLegend}
                      >
                        <span
                          style={{ backgroundColor: color }}
                          tw="rounded-[50%] w-[1.25rem] h-[1.25rem] block"
                        ></span>
                        <span>{value}</span>
                      </button>
                    ),
                  )}
        </div>
      </div>
      <MobileYearDropdown />
      <MobileSubHeader />
    </>
  );
};

export default Legend;
