import { SelectedCountry } from 'interfaces/countryLayer.interface';

const handleActiveScenarioTypeId = (activeScenarioTypeId: string) =>
  activeScenarioTypeId === 'INDIRECT_QUALITY'
    ? 'indirectQuality'
    : activeScenarioTypeId === 'DIRECT_QUALITY'
      ? 'directQuality'
      : activeScenarioTypeId.toLowerCase();

export const handleLayer = (
  mapData: any,
  selectedCountry: string,
  activeScenarioTypeId: string
) =>
  mapData?.find(
    ({ country }: SelectedCountry) => country?.id === selectedCountry
  )?.layers[handleActiveScenarioTypeId(activeScenarioTypeId)];
