import { Actions } from 'interfaces/global-state.interface';
import { GlobalState } from 'interfaces/water-scarcity-context.interface';

export const globalStateReducer = (
  state: GlobalState,
  { type, payload }: Actions,
) => {
  switch (type) {
    case 'SELECT_SCENARIO_OR_TYPE':
      return { ...state, [payload.name]: payload.id };
    case 'SELECT_COUNTRY':
      return {
        ...state,
        selectedCountry: payload.id
          ? {
            id: payload.id,
            name: payload.name,
          }
          : null,
      };
    case 'SELECT_YEARS_SLIDER':
      return {
        ...state,
        [payload.name]: {
          fromYear: payload.id[0] || payload.id,
          toYear: payload.id[1] || (payload.id[0] || payload.id) + 10,
        },
      };
    case 'TOGGLE_USECASES':
      return {
        ...state,
        [payload.name]: !payload.id,
      };
    case 'SELECT_ACTIVE_USECASE':
      return {
        ...state,
        [payload.name]: payload.id,
      };
    default:
      state;
      throw new Error();
  }
};
