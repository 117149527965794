/* eslint-disable max-len */
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import arrow from 'assets/images/arrow.svg';
import { useWaterScarcity } from 'context/waterScarcity';
import { MobileFilters } from 'interfaces/mobile-filters.interface';
import { MarksPair } from 'interfaces/slider.interface';
import React from 'react';
/** @jsxImportSource @emotion/react */
import tw, { theme } from 'twin.macro';
import { nonWaterScarcityMarks, waterScarcityMarks } from 'utils/slider';
import { isWaterAreaSurfaceScenarioActive } from 'utils/utilities';
const CustomSelect = styled(Select)<any>(() => ({
  '.MuiInputBase-input': {
    color: theme`colors.blue.700`,
    padding: '1rem',
  },
  '.MuiSelect-icon': {
    display: 'none',
  },
}));

const MobileYearDropdown = ({ isFromMobileFilters }: MobileFilters) => {
  const backgroundStyle = {
    background: `url(${arrow}) no-repeat 100%`,
    backgroundColor: isFromMobileFilters ? theme`colors.white` : 'transparent',
    backgroundPosition: 'right 1rem center',
  };
  const { activeScenarioId, selectedYears, setSelectedYears } =
    useWaterScarcity();
  const activeMarks = isWaterAreaSurfaceScenarioActive(activeScenarioId)
    ? nonWaterScarcityMarks
    : waterScarcityMarks;
  const handleOptions = () =>
    activeMarks.map(({ value }: MarksPair) => (
      <MenuItem key={value} value={value}>
        {value}
      </MenuItem>
    ));

  return (
    <div tw="hidden sm:flex justify-between items-center mt-[1rem]">
      <div
        tw="flex flex-col"
        css={[
          tw`flex flex-col`,
          !isWaterAreaSurfaceScenarioActive(activeScenarioId)
            ? tw`w-full`
            : tw`w-[8.5rem]`,
        ]}
      >
        <span tw="uppercase tracking-[0.2em] text-blue-700">from</span>
        <CustomSelect
          style={backgroundStyle}
          tw="border border-blue-500 rounded-[0.5rem] h-[2.75rem] w-full mt-[0.5rem]"
          value={selectedYears.fromYear.toString() as string}
          onChange={(event: SelectChangeEvent) =>
            setSelectedYears({
              id: [Number(event.target.value), selectedYears.toYear],
              name: 'selectedYears',
            })
          }
        >
          {isWaterAreaSurfaceScenarioActive(activeScenarioId)
            ? handleOptions().slice(0, -1)
            : handleOptions()}
        </CustomSelect>
      </div>
      {isWaterAreaSurfaceScenarioActive(activeScenarioId) && (
        <div tw="flex flex-col">
          <span tw="uppercase tracking-[0.2em] text-blue-700">to</span>
          <CustomSelect
            style={backgroundStyle}
            tw="border border-blue-500 rounded-[0.5rem] h-[2.75rem] w-[8.5rem] mt-[0.5rem]"
            value={selectedYears.toYear?.toString() as string}
            onChange={(event: SelectChangeEvent) =>
              setSelectedYears({
                id: [selectedYears.fromYear, Number(event.target.value)],
                name: 'selectedYears',
              })
            }
          >
            {isWaterAreaSurfaceScenarioActive(activeScenarioId)
              ? handleOptions().slice(1)
              : handleOptions()}
          </CustomSelect>
        </div>
      )}
    </div>
  );
};
export default MobileYearDropdown;
