import Form from 'components/Form/Form';
import React from 'react';
import MailchimpSubscribe, { EmailFormFields } from 'react-mailchimp-subscribe';

const url =
  'https://worlddata.us13.list-manage.com/subscribe/post-json?u=3840f1496862a7a719676d23a&id=72179f20ac';

const FormContainer = ({ handleToggleState }: any) => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status }) => (
      <Form
        onValidated={(data: EmailFormFields) => subscribe(data)}
        status={status as string}
        handleToggleState={handleToggleState}
      />
    )}
  />
);

export default FormContainer;
