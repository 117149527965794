import { useWaterScarcity } from 'context/waterScarcity';
import markers from 'data/markers.json';
import L from 'leaflet';
import React, { useState } from 'react';
import { Marker, Popup } from 'react-leaflet';

const useMarkers = () => {
  const iconHeight = 52;
  const iconWidth = 48;
  const { caseStudyTheme, hoveredUsecaseTitle, setActiveUseCaseIndex } =
    useWaterScarcity();
  const [innerMarkers, setInnerMarkers] = useState({
    position: { left: 0, top: 0 },
    show: false,
  });

  const handlePinIcon = (marker: (typeof markers)[0]['data'][0]) =>
    L.icon({
      className: marker.multiIcon ? `${marker.className} multiIcon` : '',
      iconAnchor: [iconWidth / 2, iconHeight],
      iconSize: [iconWidth, iconHeight],
      iconUrl: marker.icon,
    });

  const handleMarkers = () =>
    markers[0].data
      .filter(({ themes }) => {
        if (caseStudyTheme === 'all') return true;

        return themes.includes(caseStudyTheme);
      })
      .map((marker) => (
        <Marker
          key={marker.location.lat}
          position={marker.location}
          icon={handlePinIcon(marker)}
          eventHandlers={{
            click: () => {
              if (!marker.title.includes('Jordan')) {
                setActiveUseCaseIndex({
                  id: marker.id,
                  name: 'activeUseCaseIndex',
                });
              }
            },
            mouseout: (e) => {
              if (marker.title.includes('Jordan')) {
                setTimeout(
                  () =>
                    setInnerMarkers({
                      position: { left: 0, top: 0 },
                      show: false,
                    }),
                  8000,
                );
              } else {
                e.target.closePopup();
                setActiveUseCaseIndex({
                  id: null,
                  name: 'hoveredUsecaseTitle',
                });
              }
            },
            mouseover: (e) => {
              if (marker.title.includes('Jordan')) {
                setInnerMarkers({
                  position: {
                    left:
                      e.target.getElement().getBoundingClientRect().left -
                      e.target.getElement().getBoundingClientRect().width /
                        1.25,
                    top:
                      e.target.getElement().getBoundingClientRect().top -
                      e.target.getElement().getBoundingClientRect().height / 4,
                  },
                  show: true,
                });
              } else {
                setActiveUseCaseIndex({
                  id: marker.title,
                  name: 'hoveredUsecaseTitle',
                });
                e.target.openPopup();
              }
            },
          }}
        >
          {/* 
          Offset for title popup.
          Offset is calculated from center of icon.
          Additional 10px are required because of icon design with bottom lip 
          that makes it asymetric.
      */}
          {!marker.title.includes('Jordan') && (
            <Popup offset={[0, -(iconHeight / 2 + 10)]}>
              {hoveredUsecaseTitle}
            </Popup>
          )}
        </Marker>
      ));

  return { handleMarkers, innerMarkers };
};

export default useMarkers;
