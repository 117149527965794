/* eslint-disable indent */
/* eslint-disable max-len */
import flags from 'data/countries_flags.json';
import geoJson from 'data/countries_geojson.json';
import { ApolloQueryProps, ApolloVariables } from 'interfaces/apollo.interface';
import { ScenarioProps } from 'interfaces/scenario.interface';
import React, { createContext, useContext, useReducer } from 'react';
import { globalStateReducer } from 'reducers/globalState';
import {
  useBiodiversityQuery,
  useHeroSectionQuery,
  useWaterAreaSurfaceQuery,
  useWaterScarcityQuery,
} from 'utils/__generated__/graphql';
import {
  countryStyle,
  handleHoveredLegendCategory,
  handleStyle,
} from 'utils/leaflet';
import { getActiveScenario, initialYear, scenarios } from 'utils/utilities';
import {
  ChildrenProps,
  GlobalState,
  ScenarioIdOrTypeIdOrYearsProps,
} from '../interfaces/water-scarcity-context.interface';

let mergedMapData;
let mergedHeroSectionScenarios = {};
let updatedAvailableCountries: any;

const initialState: GlobalState = {
  activeScenario: {},
  activeScenarioId: 'water_scarcity',
  activeScenarioTypeId: 'AVAILABILITY',
  activeUseCaseIndex: null,
  areUsecasesActive: true,
  availableCountries: [],
  hoveredUsecaseTitle: null,
  legendHoveredType: null,
  mapData: null,
  overallValues: {},
  selectedCountry: null,
  selectedYears: {
    fromYear: initialYear,
    toYear: initialYear + 10,
  },
  caseStudyTheme: 'all',
  setActiveCountry: null,
  setActiveScenarioIdOrTypeId: null,
  setActiveUseCaseIndex: null,
  setAreUsecasesActive: null,
  setHoveredType: null,
  setSelectedYears: null,
};

const WaterScarcityContext = createContext(initialState);

export const WaterScarcityContextProvider = ({ children }: ChildrenProps) => {
  const [state, dispatch] = useReducer(globalStateReducer, initialState);

  const variables: ApolloVariables = {
    decade: state.selectedYears.fromYear,
    fromYear: state.selectedYears.fromYear,
    toYear: state.selectedYears.toYear,
  };

  const {
    loading: isHeroDataLoading,
    data: heroSectionData,
  }: ApolloQueryProps = useHeroSectionQuery({
    variables,
  });

  const { data: waterAreaSurface }: ApolloQueryProps = useWaterAreaSurfaceQuery(
    {
      skip: state.activeScenarioId !== 'water_area_surface',
      variables,
    },
  );

  const { data: biodiversity }: ApolloQueryProps = useBiodiversityQuery({
    skip: state.activeScenarioId !== 'biodiversity',
    variables,
  });

  const { data: waterScarcity }: ApolloQueryProps = useWaterScarcityQuery({
    skip: state.activeScenarioId !== 'water_scarcity',
    variables,
  });

  mergedMapData = waterAreaSurface || biodiversity || waterScarcity;

  !isHeroDataLoading &&
    heroSectionData &&
    Object.keys(heroSectionData)?.map((key) => {
      const keyOfScenario = key as string as keyof typeof scenarios;
      mergedHeroSectionScenarios = {
        ...mergedHeroSectionScenarios,
        [keyOfScenario]: {
          ...heroSectionData[keyOfScenario],
          ...scenarios[keyOfScenario],
        },
      };
    });

  const handleReduceDataIds = (array: any[], handleId: (elem: any) => string) =>
    array.reduce((obj: any, item: any) => {
      obj[handleId(item)] = item;
      return obj;
    }, {});

  if (mergedMapData) {
    const geoJsonIds = handleReduceDataIds(
      geoJson[0].features,
      (item) => item.id,
    );
    const flagsIds = handleReduceDataIds(flags[0].data, (item) => item.iso3c);
    const mapSectionDataIds = handleReduceDataIds(
      mergedMapData.data.values,
      (item) => item.country.id,
    );
    const ids = [...Object.keys(geoJsonIds)];
    const newSetIds: any = new Set(ids);
    mergedMapData = [...newSetIds]
      .map((id) => ({
        ...geoJsonIds[id],
        ...flagsIds[id],
        ...mapSectionDataIds[id],
      }))
      .map((item) =>
        item.stats
          ? {
              ...item,
              hoveredCategory: handleHoveredLegendCategory(
                state.activeScenarioId,
                item.stats[state.activeScenarioTypeId.toLowerCase()],
              ),
              style: {
                ...countryStyle,
                fillColor: handleStyle(
                  state.activeScenarioId,
                  item.stats[state.activeScenarioTypeId.toLowerCase()],
                ),
              },
            }
          : {
              ...item,
              hoveredCategory: 'NA',
              style: {
                ...countryStyle,
              },
            },
      );
    updatedAvailableCountries = mergedMapData?.filter(
      ({ stats, file_url }: any) => stats && file_url,
    );
  }

  const activeScenario: ScenarioProps = getActiveScenario(
    state.activeScenarioId,
    mergedHeroSectionScenarios,
  );

  const setActiveScenarioIdOrTypeId = ({
    id,
    name,
  }: ScenarioIdOrTypeIdOrYearsProps) =>
    dispatch({
      payload: { id, name },
      type: 'SELECT_SCENARIO_OR_TYPE',
    });

  const setHoveredType = ({ id, name }: ScenarioIdOrTypeIdOrYearsProps) =>
    dispatch({
      payload: { id, name },
      type: 'SELECT_SCENARIO_OR_TYPE',
    });

  const setActiveCountry = ({ id, name }: ScenarioIdOrTypeIdOrYearsProps) =>
    dispatch({
      payload: { id, name },
      type: 'SELECT_COUNTRY',
    });

  const setAreUsecasesActive = ({ id, name }: ScenarioIdOrTypeIdOrYearsProps) =>
    dispatch({
      payload: { id, name },
      type: 'TOGGLE_USECASES',
    });

  const setActiveUseCaseIndex = ({
    id,
    name,
  }: ScenarioIdOrTypeIdOrYearsProps) =>
    dispatch({
      payload: { id, name },
      type: 'SELECT_ACTIVE_USECASE',
    });

  const setSelectedYears = ({ id, name }: ScenarioIdOrTypeIdOrYearsProps) =>
    dispatch({
      payload: { id, name },
      type: 'SELECT_YEARS_SLIDER',
    });

  const value = {
    activeScenario,
    activeScenarioId: state.activeScenarioId,
    activeScenarioTypeId: state.activeScenarioTypeId,
    activeUseCaseIndex: state.activeUseCaseIndex,
    areUsecasesActive: state.areUsecasesActive,
    availableCountries: updatedAvailableCountries,
    hoveredUsecaseTitle: state.hoveredUsecaseTitle,
    legendHoveredType: state.legendHoveredType,
    mapData: mergedMapData,
    overallValues: mergedHeroSectionScenarios,
    selectedCountry: state.selectedCountry,
    selectedYears: state.selectedYears,
    caseStudyTheme: state.caseStudyTheme,
    setActiveCountry,
    setActiveScenarioIdOrTypeId,
    setActiveUseCaseIndex,
    setAreUsecasesActive,
    setHoveredType,
    setSelectedYears,
  };

  return (
    <WaterScarcityContext.Provider value={value}>
      {children}
    </WaterScarcityContext.Provider>
  );
};

export const useWaterScarcity = () => {
  const context = useContext(WaterScarcityContext);
  if (context === undefined)
    throw new Error(
      'useWaterScarcity needs to be within the WaterScarcityContext',
    );

  return context;
};
