import facebook from 'assets/images/footer/facebook.svg';
import instagram from 'assets/images/footer/instagram.svg';
import linkedin from 'assets/images/footer/linkedin.svg';
import twitter from 'assets/images/footer/twitter.svg';
import website from 'assets/images/footer/website.svg';

export const links = [
  { image: website, name: 'website', url: 'https://worlddata.io/' },
  {
    image: linkedin,
    name: 'linkedIn',
    url: 'https://www.linkedin.com/company/world-data-lab',
  },
  {
    image: twitter,
    name: 'twitter',
    url: 'https://twitter.com/worlddatalab',
  },
  {
    image: facebook,
    name: 'facebook',
    url: 'https://www.facebook.com/worlddatalab/',
  },
  {
    image: instagram,
    name: 'instagram',
    url: 'https://instagram.com/worlddatalab',
  },
];
