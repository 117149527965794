import { useWaterScarcity } from 'context/waterScarcity';
import { useCallback } from 'react';

const useLeaflet = (mapRef?: any) => {
  const { setHoveredType } = useWaterScarcity();

  const handleResize = useCallback(() => {
    mapRef?.target.invalidateSize();
    window.innerWidth < 900
      ? mapRef?.target.setZoom(1)
      : mapRef?.target.setZoom(1.5);
  }, [mapRef?.target]);

  const handleMouseEnterLegend = ({ currentTarget }: any) => {
    setHoveredType({
      id: currentTarget.getAttribute('name'),
      name: 'legendHoveredType',
    });
  };

  const handleMouseLeaveLegend = () =>
    setHoveredType({
      id: null,
      name: 'legendHoveredType',
    });

  return { handleMouseEnterLegend, handleMouseLeaveLegend, handleResize };
};

export default useLeaflet;
