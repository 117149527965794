import Footer from 'components/Footer/Footer';
import About from 'components/Main/AboutPage';
import HeroSection from 'components/Main/HeroSection';
import MapSection from 'components/Main/MapSection';
import UseCasesSection from 'components/Main/UseCasesSection';
import { useWaterScarcity } from 'context/waterScarcity';
import React, { useEffect } from 'react';

const WaterScarcity = () => {
  const { activeUseCaseIndex, activeScenarioId } = useWaterScarcity();

  useEffect(() => {
    history.pushState(null, '', `#${activeScenarioId}`);
  }, [activeScenarioId]);

  return (
    <>
      <main>
        <HeroSection />
        {activeUseCaseIndex === null ? <MapSection /> : <UseCasesSection />}
        <About />
      </main>
      <Footer />
    </>
  );
};

export default WaterScarcity;
