/* eslint-disable max-len */
import close from 'assets/images/blueClose.svg';
import { useWaterScarcity } from 'context/waterScarcity';
import { t } from 'i18next';
import { SelectedCountry } from 'interfaces/countryLayer.interface';
import React, { useLayoutEffect, useRef } from 'react';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { months, handleMonthIndex } from 'utils/utilities';

const Details = () => {
  const { selectedCountry, selectedYears, mapData, setActiveCountry } =
    useWaterScarcity();
  const driestMonth = useRef<HTMLLIElement | null>(null);

  const handleDetails = () =>
    mapData?.find(
      ({ country }: SelectedCountry) => country?.id === selectedCountry.id,
    )?.stats;

  useLayoutEffect(
    () =>
      driestMonth.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      }),
    [],
  );

  const monthIndex = months.indexOf(handleDetails()?.driestMonth) + 1;

  return (
    <article
      className="scrollable"
      tw="bg-orange-100 max-w-[28rem] lg:max-w-full sm:max-w-none w-full"
    >
      <div tw="flex flex-col gap-[0.5rem] py-[0.5rem] px-[4rem] xl:px-[1rem] text-sm text-orange-800 uppercase">
        <p tw="tracking-[0.15rem]">
          {t('select_country_water_scarcity_water_avalability_name')}
        </p>
        <button
          tw="absolute right-[6%]"
          onClick={() =>
            setActiveCountry({
              id: null,
              name: null,
            })
          }
        >
          <img src={close} alt="Close" />
        </button>
        <p tw="text-black text-regular">{selectedYears.fromYear}</p>
      </div>
      <div
        tw="overflow-auto max-h-[21rem] bg-orange-100"
        className="scrollable"
      >
        <div tw="p-[0 4rem 1.5rem] text-black xl:p-[0 1rem 1.5rem]">
          <p tw="font-medium text-xl text-black capitalize">
            {selectedCountry.name}
          </p>
          <p tw="tracking-[0.15rem] text-sm text-orange-800 uppercase">
            {t('select_country_water_scarcity_water_avalability_dry')}
          </p>
        </div>
        <ul
          tw="flex bg-orange-50 place-content-between py-[1rem] gap-[2rem] overflow-hidden text-orange-200 font-medium text-xl"
          css={[
            handleMonthIndex(monthIndex, 'first') && tw`justify-start`,
            handleMonthIndex(monthIndex, 'middle') && tw`justify-center`,
            handleMonthIndex(monthIndex, 'last') && tw`justify-end`,
          ]}
        >
          {months.map((month: string) => (
            <li
              css={[
                handleDetails()?.driestMonth === month && tw`text-orange-600`,
              ]}
              ref={handleDetails()?.driestMonth === month ? driestMonth : null}
              key={month}
            >
              {month}
            </li>
          ))}
        </ul>
        <div tw="p-[2rem 4rem 1.5rem] text-black xl:p-[2rem 1rem 1.5rem]">
          <p
            tw="text-sm leading-4 tracking-[.2em]
                        text-orange-800 uppercase"
          >
            {t('select_country_water_scarcity_water_avalability_index_name')}
          </p>
          <p tw="p-[0.5rem 0 1.5rem]">
            {t(
              'select_country_water_scarcity_water_avalability_index_description',
            )}
          </p>
          <div tw="flex justify-between items-center border-b border-orange-300 font-semiBold text-orange-800 pt-4">
            <p>
              {t(
                'select_country_water_scarcity_water_avalability_index_value_1',
              )}
            </p>
            <div tw="text-right text-black">
              <p tw="font-medium text-xl">
                {handleDetails()?.waterStress.toLocaleString()}
              </p>
              <p tw="font-regular">
                {t(
                  'select_country_water_scarcity_water_avalability_index_people',
                )}
              </p>
            </div>
          </div>
          <div tw="flex justify-between items-center border-b border-orange-300 font-semiBold text-orange-800 pt-4">
            <p>
              {t(
                'select_country_water_scarcity_water_avalability_index_value_2',
              )}
            </p>
            <div tw="text-right text-black">
              <p tw="font-medium text-xl">
                {handleDetails()?.waterScarcity.toLocaleString()}
              </p>
              <p tw="font-regular">
                {t(
                  'select_country_water_scarcity_water_avalability_index_people',
                )}
              </p>
            </div>
          </div>
          <div tw="flex justify-between items-center border-b border-orange-300 font-semiBold text-orange-800 pt-4">
            <p>
              {t(
                'select_country_water_scarcity_water_avalability_index_value_3',
              )}
            </p>
            <div tw="text-right text-black">
              <p tw="font-medium text-xl">
                {handleDetails()?.absoluteScarcity.toLocaleString()}
              </p>
              <p tw="font-regular">
                {t(
                  'select_country_water_scarcity_water_avalability_index_people',
                )}
              </p>
            </div>
          </div>
          <div tw="flex justify-between items-center border-b border-orange-300 font-semiBold text-orange-800 pt-4">
            <p>
              {t(
                'select_country_water_scarcity_water_avalability_index_value_4',
              )}
            </p>
            <p tw="text-right text-black font-medium text-xl w-[6.25rem]">
              {handleDetails()?.availability.toLocaleString()} %
            </p>
          </div>
          <div tw="flex justify-between items-center font-semiBold text-orange-800 pt-4">
            <p>
              {t(
                'select_country_water_scarcity_water_avalability_index_value_5',
              )}
            </p>
            <div tw="text-right text-black">
              <p tw="font-medium text-xl">
                {handleDetails()?.population.toLocaleString()}
              </p>
              <p tw="font-regular">
                {t(
                  'select_country_water_scarcity_water_avalability_index_people',
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Details;
