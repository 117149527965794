import React from 'react';
import FooterPartners from './FooterPartners';
import FooterSocial from './FooterSocial';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const Footer = () => (
  <footer>
    <FooterSocial />
    <FooterPartners />
  </footer>
);

export default Footer;
