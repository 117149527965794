/* eslint-disable max-len */
import closeIcon from 'assets/images/closeFilters.svg';
import filtersIcon from 'assets/images/filters.svg';
import AsideMobileFilters from 'components/Filters';
import useToggle from 'hooks/useToggle';
import { t } from 'i18next';
import React, { useLayoutEffect } from 'react';
/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { handleOverflow } from 'utils/utilities';

const MobileFiltersButton = () => {
  const { handleToggleState, toggleState } = useToggle();

  useLayoutEffect(() => {
    handleOverflow(toggleState.areMobileFiltersActive);
  }, [toggleState.areMobileFiltersActive]);

  return (
    <>
      <button
        onClick={() => handleToggleState('areMobileFiltersActive')}
        tw="hidden sm:flex sm:justify-between sm:items-center text-blue-800 gap-[1.125rem] w-[7rem] !bg-no-repeat pl-[4rem] ![background-position: left]"
        style={{
          background: `url(${
            toggleState.areMobileFiltersActive ? closeIcon : filtersIcon
          })`,
        }}
      >
        {toggleState.areMobileFiltersActive ? t('form_close') : t('Filters')}
      </button>
      <AsideMobileFilters
        areMobileFiltersActive={toggleState.areMobileFiltersActive}
        handleToggleState={handleToggleState}
      />
    </>
  );
};

export default MobileFiltersButton;
